const adjectives = [
    'decisive',
    'fabulous',
    'massive',
    'crisp',
    'tattered',
    'reckless',
    'previous',
    'ultimate',
    'orange',
    'sugary',
    'bronze',
    'greedy',
    'careless',
    'sparse',
    'sugary',
    'acidic',
    'unwelcome',
    'husky',
    'truthful',
    'hoarse',
    'wary',
    'brown',
    'dishonest',
    'unfolded',
    'elementary',
    'rosy',
    'regular',
    'hasty',
    'frightening',
    'hungry',
    'dull',
    'terrible',
    'jolly',
    'sticky',
    'agitated',
    'weary',
    'impressionable',
    'flat',
    'remorseful',
    'pungent',
    'unruly',
    'blue',
    'sophisticated',
    'super',
    'able',
    'salty',
    'hasty',
    'illustrious',
    'practical',
    'realistic',
    'poor',
    'hurtful',
    'sparse',
    'watchful',
    'nifty',
    'next',
    'assured',
    'gentle',
    'jumpy',
    'bewitched',
    'calculating',
    'healthy',
    'narrow',
    'fatherly',
    'hairy',
    'stark',
    'defensive',
    'loathsome',
    'oblong',
    'hateful',
    'giant',
    'thrifty',
    'thick',
    'untidy',
    'soulful',
    'adept',
    'gorgeous',
    'outrageous',
    'smoggy',
    'medical',
    'incomparable',
    'married',
    'warmhearted',
    'agreeable',
    'innocent',
    'blushing',
    'precious',
    'formal',
    'exotic',
    'breakable',
    'sour',
    'shocking',
    'tinted',
    'tasty',
    'snappy',
    'unfit',
    'major',
    'creative',
    'marvelous',
    'fussy',
];

const nouns = [
    'people',
    'history',
    'way',
    'art',
    'world',
    'information',
    'map',
    'two',
    'family',
    'government',
    'health',
    'system',
    'computer',
    'meat',
    'year',
    'thanks',
    'music',
    'person',
    'reading',
    'method',
    'data',
    'food',
    'understanding',
    'theory',
    'law',
    'bird',
    'literature',
    'problem',
    'software',
    'control',
    'knowledge',
    'power',
    'ability',
    'economics',
    'love',
    'internet',
    'television',
    'science',
    'library',
    'nature',
    'fact',
    'product',
    'idea',
    'temperature',
    'investment',
    'area',
    'society',
    'activity',
    'story',
    'industry',
    'media',
    'thing',
    'oven',
    'community',
    'definition',
    'safety',
    'quality',
    'development',
    'language',
    'management',
    'player',
    'variety',
    'video',
    'week',
    'security',
    'country',
    'exam',
    'movie',
    'organization',
    'equipment',
    'physics',
    'analysis',
    'policy',
    'series',
    'thought',
    'basis',
    'boyfriend',
    'direction',
    'strategy',
    'technology',
    'army',
    'camera',
    'freedom',
    'paper',
    'environment',
    'child',
    'instance',
    'month',
    'truth',
    'marketing',
    'university',
    'writing',
    'article',
    'department',
    'difference',
    'goal',
    'news',
    'audience',
    'fishing',
    'growth',
];

export function generateRandomName() {
    return (
        adjectives[Math.floor(Math.random() * adjectives.length)] +
        '-' +
        nouns[Math.floor(Math.random() * nouns.length)]
    );
}
