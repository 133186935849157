import React, { ReactElement } from 'react';
import { appColors } from '../styled/useTheme';

export function HeaderLogo(): ReactElement {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="6.66667" fill={appColors.purple.w500} />
            <path
                d="M23.9701 12.5533C23.9701 11.2475 22.9116 10.189 21.6057 10.189H20.9209V26.8679H23.9701V18.4093C25.6677 17.6458 27.8517 18.1578 27.8517 19.9259V26.869H30.9494V19.9701C30.8567 16.0479 28.0307 14.0972 23.9701 15.2425V12.5533Z"
                fill={appColors.ui.white}
            />
            <path
                d="M14.3426 24.7454C15.6343 24.7454 16.2794 24.3783 16.2794 23.5928C16.2794 22.9699 15.2186 22.4638 13.1205 22.094C10.4232 21.6101 9.03983 20.4116 9.01758 18.4985C9.01758 15.8694 11.092 14.625 13.8838 14.5791C15.4522 14.5791 16.6975 14.9248 17.6197 15.6163C18.5179 16.331 19.0017 17.3223 19.0713 18.5903H17.1317C16.4171 18.5666 15.9569 18.3122 15.7719 17.8298C15.5648 17.2986 14.9377 16.9955 13.9269 16.9955C12.7284 16.9955 12.1292 17.3181 12.1292 17.941C12.1292 18.5639 12.9134 19.0463 14.5039 19.3466C16.5783 19.7637 17.9395 20.2921 18.6069 20.915C18.8849 21.2019 19.1035 21.541 19.25 21.9128C19.3965 22.2846 19.4681 22.6816 19.4606 23.0811C19.4606 26.0787 17.1095 27.1854 13.8811 27.1854C12.1751 27.1854 10.8848 26.8156 10.0548 26.1009C9.13298 25.4058 8.67139 24.3491 8.67139 22.9435H11.8066C11.8762 24.2838 12.8438 24.7454 14.3426 24.7454Z"
                fill={appColors.ui.white}
            />
        </svg>
    );
}
